import React, { Component} from 'react'

import jumpTo,{jumpToData} from '../../modules/Navigation'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/intermediaryPage.css'
import Modal from "react-bootstrap/Modal";
import FooterContainer from '../../components/footer/footerContainer'
import Auth from '../../modules/Auth'
import {Translator, Translate} from 'react-auto-translate';
import serverCall from '../../modules/serverCall'
import serverUrl from '../../configs/constant'

export default class intermediaryPage extends Component {
  constructor(props) {
    super(props);
    Auth.getLocation();
    this.state = {
        isOpen: false,
        keyword:'',
        productCount:'',
        category:'',
        subcategoryone:'',
        subcategorytwo:'',
        subcategorythree:'',
        devicetype:localStorage.getItem('devicetype'),
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
        deviceos:localStorage.getItem('deviceos'),
        devicebrowser:localStorage.getItem('devicebrowser'),
        latitude: localStorage.getItem('latitude'),
        longitude:localStorage.getItem('longitude'),
        userdevice:localStorage.getItem('userdevice'),
      };
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
    setInterval(this.getNumber, 10000);
    const fetchData = async() =>{
        var sendData = {
          "devicetype":this.state.devicetype,
          "deviceos":this.state.deviceos,
          "devicebrowser":this.state.devicebrowser,
          "latitude":this.state.latitude,
          "longitude":this.state.longitude,
          "userdevice":this.state.userdevice
        }
        return serverCall({
            method:'POST',
            url:`/api/landing/landing-home`,
            data:sendData
          })
          .then(res=>{
            console.log('res.data',res.data);
            this.setState({
                productCount:res.data,
             })
          })
          .catch(error=>{
            console.log('error',error)
            return error
          })
     }
     fetchData();
    this.props.getCategoryData()
  }


  handleChangeKeyword = (event) => {
    this.setState({ keyword: event.target.value });
  }


  openModal = () => this.setState({ isOpen: true });
  closeModal = () => {
    this.setState({ isOpen: false });
  }

// const cacheProvider = {
//  get: (language, key) =>
//    ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
//      language
//    ],
//  set: (language, key, value) => {
//    const existing = JSON.parse(localStorage.getItem('translations')) || {
//      [key]: {},
//    };
//    existing[key] = {...existing[key], [language]: value};
//    localStorage.setItem('translations', JSON.stringify(existing));
//  },
// };

  render() {

    
    
    var productCount = this.state.productCount;

    const logocompany = require('../../assets/images/rlogo.png');
    const logocity = require('../../assets/images/store01.png');
    const logoCountCity = require('../../assets/images/shop01.png');
    const SearchIcon = require('../../assets/images/IconSearch.png');
    const googlePin = require('../../assets/images/googlePin.png');


    return (
      <span>
      <HeaderContainer />
          
           <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
        <div className=" intermediaryContainer">
          <div className="categories_search_bar">
              <div className="container">  
                  <div className="intermediaryLogo">
                    <a onClick={() => jumpTo('/marketplace-home')} >
                      <img style={{"width":"210px"}}src={logocompany}/>
                    </a>
                  </div>
                  <div className="flex_div intermediarySearch">
                   <form className="navbar-form intermediaryBar" onSubmit={() => jumpToData('/advance-search/',{keyword:this.state.keyword,name:'keyword',code:this.state.keyword})}>
                    <input className="form-control" onChange ={this.handleChangeKeyword} placeholder="Search by Keyword . . ." type="text" />
                    <button className="btn blue_btn intermediarySearchBar" onClick ={() => jumpToData('/advance-search/',{keyword:this.state.keyword,name:'keyword',code:this.state.keyword})} type="button"><i className="fas fa-search"></i></button>
                   </form> 
                  </div>
            </div>
          </div>
          
          <div className=" intermediaryBtnCenter">
             <div className=" intMicContainer">
                <div className="intermediaryMic">
                 <a className="voice_serch intermediaryMicIcon" title="Search by Voice Coming Soon"><i className="fas fa-microphone"></i></a>
                </div>
             </div>
             <div className="intMicContainer">
                  <a className="hamburgerIcon" title=" CLICK HERE TO SEARCH FOR PRODUCTS AT YOUR LOCATION" onClick={() => jumpTo('/landing-marketplace')}>
                  <img className="fas fa-th" className="SearchPin" src={googlePin} />
                  </a>
             </div>
             <div className="intMicContainer">
                  <a className="hamburgerIcon" title="Search by Image Coming Soon">
                    <img className="fas fa-th" className="SearchImg" src={SearchIcon} />
                        
                  </a>
             
             </div>
             
                 {/*<div className="upArrowContainer">
                      <a href="#" className="upArrow up">Up</a>
                  </div>*/}
                  
          </div>
          <button className="cardOne"  onClick={() => jumpToData('/product-result/'+productCount.city,{cityname:productCount.city,name:productCount.city,code:productCount.countryCode})}>
                    <div className="inner">
                      <span className="interactiveTootip">
                       Shop in <br/> {productCount.city}
                      </span>
                    </div>
                  </button>

           {/* Footer */}
      <div className="intmediaryFooter">
        <FooterContainer />
      </div>
      </div>
          {/* Home Page Popup  css Strat */}
            </Translator>
      </span>
    )
  }
}





